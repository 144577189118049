<template>
  <div id="component">
    <header>
      <nav>
        <router-link :to="{ name: 'Home' }"> <img src="../assets/back.svg" alt="" /> {{ __("back")}} </router-link>
      </nav>
      <img class="logo" src="../assets/logo.svg" />
    </header>
    <div class="text">
      <div class="thank">
        <h1>{{ __("thanks")}}</h1>
        <p>{{ __("contacted")}}</p>
      </div>
      <div class="info">
        <div class="location">
          <p style="font-weight: bold">{{ vehicle.dealer ? vehicle.dealer.name : "" }}</p>
          <p>{{ vehicle.dealer ? vehicle.dealer.city : "" }}</p>
        </div>
        <p>{{ vehicle.dealer ? vehicle.dealer.phone : "" }}</p>
        <p>{{ vehicle.dealer ? vehicle.dealer.email : "" }}</p>
      </div>
      <div class="motorcycle">
        <img :src="vehicle.beauty_shot" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      vehicle: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get("/api/vehicles/" + this.$route.params.id).then((response) => {
        this.vehicle = response.data;
      });
    },
  },
};
</script>


<style scoped lang="scss">
@import "./scss/media/general/confirm.scss";
@import "./scss/media/1440/confirm.scss";
@import "./scss/media/1280/confirm.scss";
@import "./scss/media/1024/confirm.scss";
@import "./scss/media/850/confirm.scss";
@import "./scss/media/550/confirm.scss";
</style>